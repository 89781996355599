"use client";

import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { useTheme } from "next-themes";
import "./styles/button.css";
const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground text-tx-primary hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-input  hover:text-brand-foreground dark:buttonStylesDark buttonStylesLight",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-brand hover:text-brand-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        var1: "font-semibold",
        var2: "text-color-primary rounded-md bg-container-background-primary font-semibold",
        var3: "bg-container-background-primary font-semibold",
        text: "text-color-primary font-semibold py-10 px-5",
        dot: "text-color-primary font-semibold py-10 px-5",
        lineddark: "text-color-primary font-semibold py-10 px-5",
        ShinyLight: "text-color-primary shine",
        mixedVariant:
          "text-color-primary font-semibold py-10 px-5 rounded-full border-double border-[rgba(255, 255, 255, 0.07)] border-[1px] bg-[rgba(0, 0, 0, 0.50)] shadow-md backdrop-blur-[10px]"
      },
      size: {
        default: "h-10  px-4 py-2",
        sm: "h-9 rounded-md px-3",
        md: "h-10 rounded-md px-5",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10"
      },
      effect: {
        outline: "",
        flat: "border-transparent",
        glass: ""
      }
    },
    defaultVariants: {
      variant: "default",
      // variant: "outline",
      size: "default",
      effect: "outline"
    }
  }
);
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  glow?: Boolean;
  // effect: string;
}

const applyShine = (theme: any, variant: any, effect: any) => {
  console.log("variant");
  let class1 = "";
  if (theme === "dark" && variant === "outline") {
    // if (theme === "dark" && variant==="outline" && effect==="outline") {
    return class1 + "buttonStylesDark";
  }
  if (theme === "light" && variant === "outline") {
    // if (theme === "light" && variant==="outline" && effect==="outline") {
    return class1 + "buttonStyleslight";
  }
  return "";
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, leftIcon, rightIcon, effect, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const { theme } = useTheme();
    const [mounted, setMounted] = React.useState(false);
    React.useEffect(() => {
      setMounted(true);
    }, []);
    return (
      mounted && (
        <Comp
          className={cn(
            buttonVariants({ variant, size, className }),
            `flex justify-center items-center ${applyShine(theme, variant, effect)}`
          )}
          ref={ref}
          {...props}
        >
          {leftIcon && <span className="mr-2">{leftIcon}</span>} {props.children}
          {rightIcon && <span className="ml-2 font font-semibold">{rightIcon}</span>}
          <div className="shine"></div>
        </Comp>
      )
    );
  }
);
Button.displayName = "Button";
export { Button, buttonVariants };
