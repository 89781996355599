import { cn } from "@/lib/utils";
import { FormikErrors } from "formik";
import { forwardRef } from "react";
import Label from "./label";
import { Text } from "./text";
import TextareaAutosizeBase, { TextareaAutosizeProps } from "react-textarea-autosize";

export interface TextareaProps extends TextareaAutosizeProps {
  showMaxlengthLabel?: boolean;
  value?: string;
  error?: boolean;
  errorOutline?: boolean;
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  label?: React.ReactNode | undefined;
  helperText?: string | undefined;

  labelClassName?: string | undefined;
  textareaClassName?: string | undefined;
  helperClassName?: string | undefined;
  errorClassName?: string | undefined;

  unstyled?: boolean;
  resizable?: boolean;
}

export const TextareaAutosize = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      value,
      maxLength,
      showMaxlengthLabel,
      error,
      errorMessage,
      errorOutline,
      label,
      helperText,
      labelClassName,
      textareaClassName,
      helperClassName,
      errorClassName,
      unstyled = false, // DEFAULT UNSTYLED TO FALSE
      resizable = false, // DEFAULT RESIZABLE TO FALSE
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn("relative", className)}>
        {label ? (
          <div className=" mb-s">
            <Label className={labelClassName}>{label}</Label>
          </div>
        ) : null}
        <div className="relative w-full">
          <TextareaAutosizeBase
            ref={ref}
            maxLength={maxLength}
            value={value}
            className={cn(
              "flex px-m outline-none py-m rounded-lg w-full disabled:cursor-not-allowed placeholder-color-tertiary disabled:opacity-50  ring-offset-background",
              {
                "field-border bg-container": !unstyled,
                "resize-none": !resizable,
                resize: resizable,
                "border-red-500 border-1 rounded-lg border-solid": errorOutline
              },
              textareaClassName
            )}
            {...props}
          />
          {maxLength && showMaxlengthLabel && (
            <Text
              variant="caption_two"
              as="span"
              className="text-muted-foreground absolute bottom-2 right-2"
            >
              {`${value?.length || "0"}/${maxLength || "0"}`}
            </Text>
          )}
        </div>
        {error && errorMessage && (
          <Text as="span" className={cn("text-red-500 text-xs mx-l", errorClassName)}>
            {typeof errorMessage === "object" ? JSON.stringify(errorMessage) : errorMessage}
          </Text>
        )}
        {helperText ? (
          <Text
            as="span"
            variant="caption_one"
            className={cn(
              "text-secondary-foreground text-xs mx-l mt-s mb-s block",
              helperClassName
            )}
          >
            {helperText}
          </Text>
        ) : null}
      </div>
    );
  }
);
TextareaAutosize.displayName = "TextareaAutosize";
