"use client";

import * as React from "react";
import { DayPicker } from "react-day-picker";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { buttonVariants } from "./buttonv1";
import { cn } from "@/lib/utils";

export type CalendarProps = React.ComponentProps<any> & {
  size?: "small" | "large";
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  disableDay,
  size = "large",
  ...props
}: CalendarProps) {
  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const modifiers = {
    weekend: (date: Date) => isWeekend(date),
    disabled: { before: disableDay }
  };

  const sizeClasses: any = {
    small: {
      root: "bg-container w-full text-center rounded-8 backdrop-blur-2xl text-300  py-l",
      caption_label: "text-body2 absolute left-6 font-semibold",
      nav_button: "h-7 w-7  p-0  text-primary",
      head_cell: "text-color-primary rounded-md w-7 font-normal text-300 mx-2",
      cell: "h-7 w-7 mx-2 text-center p-s relative [&:has([aria-selected].day-range-end)]:rounded-r-md  first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 rounded-full flex justify-center items-center has-[button]:hover:border-2 has-[button]:hover:border-black-500 flex justify-center items-center bg-transparent has-[button]:hover:bg-[#000000]",
      day: cn(
        buttonVariants({ variant: "ghost", className: "px-2 py-2" }),
        "font-normal text-300 text-black hover:text-white transition-none rounded-full "
      ),
      day_selected: "h-6 w-6   flex justify-center items-center  text-white  !bg-[#000000]",
      nav_button_previous: "absolute right-14 text-primary",
      nav_button_next: "absolute right-4"
    },
    large: {
      root: "bg-container w-full rounded-8 backdrop-blur-2xl ",
      caption_label: "text-xl absolute left-6 font-medium",
      nav_button: "h-7 w-7  p-0  text-primary",
      head_cell: "text-color-primary rounded-md w-8 font-normal text-sm mx-2",
      cell: "h-8 w-8 mx-2 text-center relative [&:has([aria-selected].day-range-end)]:rounded-r-md  first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 rounded-full  bg-transparent hover:bg-[#000000] flex justify-center items-center hover:border-3 hover:border-black-500 flex justify-center  items-center",
      day: cn(
        buttonVariants({ variant: "ghost" }),
        "font-normal text-md  bg-transparent  transition-none rounded-full text-black hover:text-white "
      ),
      day_selected: "h-8 w-8 flex justify-center items-center text-white !bg-[#000000] ",
      nav_button_previous: "absolute right-14 text-primary",
      nav_button_next: "absolute right-4"
    }
  };

  const selectedSizeClasses = sizeClasses[size];

  return (
    <>
      <DayPicker
        // showOutsideDays={showOutsideDays}
        className={cn("p-1", className, selectedSizeClasses.root)}
        ISOWeek
        modifiers={modifiers}
        classNames={{
          root: selectedSizeClasses.root,
          months:
            "flex flex-col sm:flex-row text-color-primary w-fit space-y-4 sm:space-x-4 sm:space-y-0 mx-auto",
          month: "space-y-4",
          caption: "flex justify-center p-4 relative items-center",
          caption_label: selectedSizeClasses.caption_label,
          nav: "space-x-1 flex items-center",
          nav_button: selectedSizeClasses.nav_button,
          nav_button_previous: selectedSizeClasses.nav_button_previous,
          nav_button_next: selectedSizeClasses.nav_button_next,
          table: "w-full border-collapse space-y-1",
          head_row: "flex",
          head_cell: selectedSizeClasses.head_cell,
          row: "flex w-full mt-2",
          cell: selectedSizeClasses.cell,
          day: selectedSizeClasses.day,
          day_range_end: "day-range-end",
          day_selected: selectedSizeClasses.day_selected,
          day_outside: "day-outside  opacity-50 text-color-tertiary",
          day_disabled: "text-muted-foreground hover:border-0 opacity-50",
          day_range_middle: "aria-selected:bg-brand aria-selected:text-brand-foreground",
          day_hidden: "invisible",
          button: "border-none bg-transparent text-color-secondary text-300",
          ...classNames
        }}
        components={{
          IconLeft: ({ ...props }) => <ArrowLeft2 size="26" {...props} />,
          IconRight: ({ ...props }) => <ArrowRight2 size="26" {...props} />
        }}
        {...props}
      />
    </>
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
