import React, { forwardRef } from "react";
import Tippy, { TippyProps } from "@tippyjs/react/headless";

type TooltipProps = {
  children: React.ReactElement;
} & Partial<TippyProps>;

export const CommonTooltip = forwardRef<HTMLElement, TooltipProps>(
  ({ children, ...props }, ref) => {
    return (
      <Tippy theme="key" touch={["hold", 500]} {...props}>
        {React.cloneElement(children, { ref })}
      </Tippy>
    );
  }
);

CommonTooltip.displayName = "CommonTooltip";
