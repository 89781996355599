// Typography.tsx
import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cnWithoutTWMerge } from "@/lib/utils";

const typographyVariants = cva("leading-normal", {
  variants: {
    size: {
      smaller: "text-xs",
      small: "text-sm",
      medium: "text-base",
      large: "text-lg",
      display: "text-4xl",
      title_one: "text-3xl",
      title_two: "text-2xl",
      title_three: "text-lg",
      subtitle_one: "text-3xl",
      body1: "text-base",
      body2: "text-sm"
    },
    weight: {
      regular: "font-normal",
      bold: "font-bold",
      extrabold: "font-extrabold",
      semibold: "font-semibold"
    },
    italic: {
      true: "italic"
    },
    uppercase: {
      true: "uppercase"
    }
  },
  defaultVariants: {
    size: "medium",
    weight: "regular",
    italic: false,
    uppercase: false
  }
});

export interface TypographyProps extends VariantProps<typeof typographyVariants> {
  as?: React.ElementType;
  children: React.ReactNode;
  className?: string;
}

/**
 * @deprecated This function will be removed in future versions. Use `Text` instead.
 * import { Text } from "@/components/ui/text";
 */
const Typography: React.FC<TypographyProps> = ({ as: Element = "div", className, ...props }) => {
  return <Element className={cnWithoutTWMerge(typographyVariants(props), className)} {...props} />;
};

export default Typography;
