"use client";

import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { cn } from "@/lib/utils";

interface PopoverContentProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {
  className?: string;
  align?: "start" | "center" | "end";
  sideOffset?: number;
  disabled?: boolean; // Additional disabled prop
}

interface PopoverTriggerProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger> {
  disabled?: boolean;
  ref?: any;
}

const Popover = PopoverPrimitive.Root;

const PopoverTrigger: React.FC<PopoverTriggerProps> = ({ disabled, children, ...props }) => {
  const triggerClassName = cn("text-current", { "text-gray-500": disabled });

  return (
    <PopoverPrimitive.Trigger className={triggerClassName} {...props} disabled={disabled}>
      {children}
    </PopoverPrimitive.Trigger>
  );
};
const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  PopoverContentProps
>(({ className, align = "center", sideOffset = 4, disabled = false, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "relative z-100 w-fit bg-container text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-20 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 backdrop-blur-40 rounded-lg",
        className,
        { "opacity-50": disabled } // Apply opacity if disabled
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));

PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent };
