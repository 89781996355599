import { useState, useEffect } from "react";

let googleMapsPromise: Promise<void> | null = null;

const loadGoogleMapsScript = (): Promise<void> => {
  if (googleMapsPromise) {
    return googleMapsPromise;
  }

  googleMapsPromise = new Promise((resolve, reject) => {
    if (document.getElementById("google-maps-script")) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}&libraries=places`;
    script.id = "google-maps-script";
    script.async = true;
    script.defer = true;

    script.onload = () => {
      resolve();
    };

    script.onerror = (error) => {
      console.error("Google Maps script failed to load:", error);
      reject(error);
    };

    document.head.appendChild(script);
  });

  return googleMapsPromise;
};

const useGoogleMaps = (): boolean => {
  const [isLoaded, setIsLoaded] = useState<boolean>(!!googleMapsPromise);

  useEffect(() => {
    loadGoogleMapsScript()
      .then(() => setIsLoaded(true))
      .catch(() => setIsLoaded(false));
  }, []);

  return isLoaded;
};

export default useGoogleMaps;
