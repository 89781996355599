import { env } from "@/env";
import React from "react";

export interface MapPreview {
  isScriptLoaded: boolean;
  selectedLocation: any;
}

const MapPreview = ({ isScriptLoaded, selectedLocation }: MapPreview) => {
  return (
    selectedLocation?.placeId &&
    isScriptLoaded && (
      <div>
        <iframe
          className="w-full rounded-lg"
          height="200"
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?q=place_id:${selectedLocation?.placeId}&key=${env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}`}
        />
      </div>
    )
  );
};

export default MapPreview;
