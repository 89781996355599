import { getSession } from "next-auth/react";
import { useAuth as authStore } from "@/stores/use-auth-store";
import ky, { Hooks, KyRequest, NormalizedOptions } from "ky";
import { ApiErrorResponse } from "@/types/api";
import merge from "lodash/merge";
import { clientIdKey, clientMemberIdKey, clientUserIdKey } from "@key.ai/constants";
import Cookies from "js-cookie";
import { env } from "@/env";
import { AuthRoutes } from "@/lib/url";

const baseApiUrl = env.NEXT_PUBLIC_EVENT_API_URL;

// Custom hook for adding authorization header
const setupHooks = (): Hooks => {
  return {
    beforeRequest: [
      // Setup default headers
      async (request: Request, options: NormalizedOptions) => {
        request.headers.set("Content-Type", "application/json");
        const clientId = Cookies.get(clientIdKey);
        if (clientId) request.headers.set(clientIdKey, clientId);
      },

      // Setup auth headers
      async (request: Request, options: NormalizedOptions) => {
        let { token, userId, setToken, setUserId } = authStore.getState();

        const memberId = Cookies.get(clientMemberIdKey);

        if (!token || !userId) {
          const session = await getSession();
          if (session?.accessToken) {
            token = session.accessToken;
            setToken(session.accessToken);
          }
          if (session?.user.id) {
            userId = session.user.id;
            setUserId(session.user.id);
          }
        }

        if (token) request.headers.set("Authorization", `Bearer ${token}`);
        if (userId) request.headers.set(clientUserIdKey, userId);
        if (userId) request.headers.set("x-user-id", userId);
        if (memberId) request.headers.set("x-member-id", memberId);

        return request;
      }
    ],
    afterResponse: [
      async (request: KyRequest, options: NormalizedOptions, response) => {
        if (response.status === 401 && request.headers.get("x-try-count")) {
          window.location.href = AuthRoutes.LOGOUT;
        }

        return response;
      }
    ],
    beforeError: [
      async (error: any) => {
        const err = (await error.response.json()) as ApiErrorResponse;
        return merge(error, err);
      }
    ],
    beforeRetry: [
      async ({ request, error, retryCount }) => {
        if ((error as any).response?.status === 401) {
          request.headers.set("x-try-count", retryCount.toString());
          const { setToken, setUserId } = authStore.getState();
          const session = await getSession();
          if (session?.accessToken) setToken(session.accessToken);
          if (session?.user.id) setUserId(session.user.id);
        }
      }
    ]
  };
};

const setupHooksImage = (): Hooks => {
  return {
    beforeRequest: [
      // Setup default headers
      async (request: Request, options: NormalizedOptions) => {
        // request.headers.set("Content-Type", "application/json");
        const clientId = Cookies.get(clientIdKey);
        if (clientId) request.headers.set(clientIdKey, clientId);
      },

      // Setup auth headers
      async (request: Request, options: NormalizedOptions) => {
        let { token, userId, setToken, setUserId } = authStore.getState();
        if (!token || !userId) {
          const session = await getSession();
          if (session?.accessToken) {
            token = session.accessToken;
            setToken(session.accessToken);
          }
          if (session?.user.id) {
            userId = session.user.id;
            setUserId(session.user.id);
          }
        }

        if (token) request.headers.set("Authorization", `Bearer ${token}`);
        if (userId) request.headers.set(clientUserIdKey, userId);
        if (userId) request.headers.set("x-user-id", userId);
        return request;
      }
    ],
    afterResponse: [
      async (request: KyRequest, options: NormalizedOptions, response) => {
        if (response.status === 401 && request.headers.get("x-try-count")) {
          window.location.href = AuthRoutes.LOGOUT;
        }

        return response;
      }
    ],
    beforeError: [
      async (error: any) => {
        const err = (await error.response.json()) as ApiErrorResponse;
        return merge(error, err);
      }
    ],
    beforeRetry: [
      async ({ request, error, retryCount }) => {
        if ((error as any).response?.status === 401) {
          request.headers.set("x-try-count", retryCount.toString());
          const { setToken, setUserId } = authStore.getState();
          const session = await getSession();
          if (session?.accessToken) {
            setToken(session.accessToken);
            request.headers.set("Authorization", `Bearer ${session.accessToken}`);
          }
          if (session?.user.id) setUserId(session.user.id);
        }
      }
    ]
  };
};

const eventClientApi = ky.create({
  hooks: setupHooks(),
  prefixUrl: baseApiUrl,
  retry: {
    limit: 1,
    statusCodes: [401],
    methods: ["get", "put", "head", "delete", "options", "trace", "post", "patch", "connect"]
  }
});

export const eventClientApiImage = ky.create({
  hooks: setupHooksImage(),
  prefixUrl: baseApiUrl,
  retry: {
    limit: 1,
    statusCodes: [401],
    methods: ["get", "put", "head", "delete", "options", "trace", "post", "patch", "connect"]
  }
});

export default eventClientApi;
