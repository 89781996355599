import { FileType } from "@key.ai/enum";
import clientApi from "@/api-client/client";
import { EndPoints } from "@/types/uploadthing";
import { ApiResponse } from "@/types/api";
import baseClient from "@/api-client/base";

export const getFileType = (file: File): FileType => {
  if (file.type.startsWith("image/")) return FileType.IMAGE;
  if (file.type.startsWith("video/")) return FileType.VIDEO;
  if (file.type.startsWith("audio/")) return FileType.AUDIO;
  return FileType.OTHER;
};

export const uploadFile = async (
  file: File,
  endPoint: EndPoints
): Promise<{ url: string; fileKey: string; thumbnailUrl: string }> => {
  const { data } = await clientApi
    .post("api/v1/uploadthing", {
      json: { contentType: file.type, endPoint }
    })
    .json<ApiResponse<{ signedUrl: string; fileKey: string }>>();

  const { headers } = await baseClient.post(data.signedUrl, {
    headers: { "x-goog-resumable": "start", "Content-Type": file.type }
  });
  const Location = headers.get("Location") as string;

  const chunkSize = 2 * 1024 * 1024; // 2 MB
  for (let start = 0; start < file.size; start += chunkSize) {
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);
    // UPLOAD THE CHUNK
    await fetch(Location, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Range": `bytes ${start}-${end - 1}/${file.size}`
      },
      body: chunk
    });
  }

  // await baseClient.put(data.signedUrl, {
  //   body: file,
  //   headers: { "Content-Type": file.type },
  // });
  // const chunkSize = 5 * 1024 * 1024; // 5MB
  // for (let start = 0; start < file.size; start += chunkSize) {
  //   const end = Math.min(start + chunkSize, file.size);
  //   const chunk = file.slice(start, end);
  //   const headers = {
  //     "X-Upload-Content-Length": `${file.size}`,
  //     "Content-Type": file.type,
  //     "Content-Range": `bytes ${start}-${end - 1}/${file.size}`,
  //   };
  //   await baseClient.put(Location, { body: chunk, headers });
  // }

  const { data: upload } = await clientApi
    .get("api/v1/uploadthing", {
      searchParams: { f: data.fileKey, mimeType: file.type }
    })
    .json<ApiResponse<{ url: string; thumbUrl: string }>>();
  return { url: upload.url, fileKey: data.fileKey, thumbnailUrl: upload.thumbUrl };
};

export const uploadPrivateFile = async (
  file: File,
  endPoint: EndPoints
): Promise<{ signedUrl: string; fileKey: string }> => {
  const { data } = await clientApi
    .post("api/v1/uploadthing", {
      json: { contentType: file.type, endPoint }
    })
    .json<ApiResponse<{ signedUrl: string; fileKey: string }>>();

  // await baseClient.put(data.signedUrl, {
  //   body: file,
  //   headers: { "Content-Type": file.type },
  // });

  const { headers } = await baseClient.post(data.signedUrl, {
    headers: { "x-goog-resumable": "start", "Content-Type": file.type }
  });
  const Location = headers.get("Location") as string;

  const chunkSize = 5 * 1024 * 1024; // 5 MB
  for (let start = 0; start < file.size; start += chunkSize) {
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);
    // UPLOAD THE CHUNK
    await fetch(Location, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Range": `bytes ${start}-${end - 1}/${file.size}`
      },
      body: chunk
    });
  }

  return data;
};
