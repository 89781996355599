import React from "react";

interface FlexProps {
  children: React.ReactNode;
  direction?: "row" | "row-reverse" | "col" | "col-reverse";
  justify?: "start" | "end" | "center" | "between" | "around";
  align?: "start" | "end" | "center" | "baseline" | "stretch";
  wrap?: "nowrap" | "wrap" | "wrap-reverse";
  className?: string;
  [key: string]: any; // Index signature for additional props
}

const Flex: React.FC<FlexProps> = ({
  children,
  direction = "row",
  justify = "start",
  align = "start",
  wrap = "nowrap",
  className = "",
  style = {}
}) => {
  const flexClasses = `flex flex-${direction} justify-${justify} items-${align} flex-${wrap} ${className} grow`;

  return (
    <div className={flexClasses} style={style}>
      {children}
    </div>
  );
};

export default Flex;
